<template>
  <div class="Part">
    <div ref="screen" class="input">
      <!-- 功能按钮 -->
      <el-row>
        <el-button type="primary" size="mini" @click="addApproval">添加审批</el-button>
        <el-button type="primary" size="mini" @click="clickModifyApproval">修改审批</el-button>
        <el-button type="primary" size="mini" @click="setApproval">设置审批流程</el-button>
        <el-button type="primary" size="mini" @click="advancedScreening">高级筛选</el-button>
        <!-- <el-button type="primary" size="mini">回收站</el-button> -->
        <!-- 审批对话框开始 添加 修改 -->
        <div class="approve">
          <el-dialog
            :title="approvalFrom.addApprovalTitle"
            :visible.sync="addApprovalDialog"
            :close-on-click-modal="false"
            width="800px"
            @close="closeApprovalDialog"
            ref="approvalDialog"
          >
            <!-- 对话框内容 -->
            <el-form :model="approvalFrom" ref="approvalFrom" size="small">
              <!-- 第一行内容 -->
              <el-row style="padding-bottom: 10px;">
                <el-col :span="12">
                  <el-form-item
                    label="审批归属"
                    label-width="80px"
                    prop="affiliation"
                    :rules="[{ required: true, message: '请选择审批归属',trigger: ['blur','change']}]"
                  >
                    <el-input :value="approvalFrom.affiliation" @click.native="selectAddressBelong('选择地址')" size="small"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12" v-if="!isPost">
                  <el-form-item
                    label="审批人"
                    label-width="80px"
                    prop="approver"
                    :rules="[{ required: true, message: '请选择审批人',trigger: ['blur','change']}]"
                  >
                    <el-cascader
                      v-if="!isArea"
                      v-model="approvalFrom.approver"
                      :options="userNameList"
                      :props="{ expandTrigger: 'hover'}"
                      size="small"
                      clearable
                      filterable
                      :disabled="approvalTypeDis"
                      style="width:100%"
                    ></el-cascader>

                    <el-cascader
                      v-if="isArea"
                      placeholder=""
                      v-model="approvalFrom.approver"
                      :options="approvalFrom.demoOpt"
                      :props="{ expandTrigger: 'hover'}"
                      size="small"
                      clearable
                      filterable
                      style="width:100%"
                    ></el-cascader>
                  </el-form-item>
                </el-col>
                <!-- <el-col :span="12" v-else>
                  <el-form-item label="审批人" label-width="80px" prop="approver2">
                    <el-input
                      v-model="approvalFrom.approver2"
                      disabled
                      size="small"
                    ></el-input>
                  </el-form-item>
                </el-col> -->
                <el-col :span="12" v-else>
                  <el-form-item label="审批人" label-width="80px" prop="approver2" 
                  :rules="[{ required: true, message: '请选择审批人',trigger: 'change'}]">
                    <el-select v-model="approvalFrom.approver2">
                      <el-option
                        v-for="item in this.approvalFrom.approver2Opt"
                        :key="item.value"
                        :label="item.suStaffName"
                        :value="item.userCoding">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>

              <!-- 第二行内容 -->
              <el-row style="padding-bottom: 10px;">
                <el-col :span="8">
                  <el-form-item label="审批类型" label-width="80px">
                    <el-select
                      v-model="approvalFrom.type"
                      size="small"
                      @change="approvalTypeChange"
                    >
                      <el-option label value></el-option>
                      <el-option
                        v-for="(item, index) in approvalFrom.typeOptions"
                        :key="index"
                        :label="item.jeapType"
                        :value="item.jeapId"
                      ></el-option>
                      <el-option label="自由流程" value="自由流程"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="审批编号" label-width="80px">
                    <el-input
                      v-model="approvalFrom.number"
                      size="small"
                      disabled
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="涉及金额" label-width="80px" size="small">
                    <el-input v-model="approvalFrom.money"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row v-show="isMore">
                <el-col :span="8">
                  <el-form-item label="账号名称" label-width="75px" size="small">
                    <el-input v-model="approvalFrom.acName"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="开户银行" label-width="75px" size="small">
                    <el-input v-model="approvalFrom.depBank"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="银行账号" label-width="75px" size="small">
                    <el-input v-model="approvalFrom.bankAc"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row v-show="isMore">
                <el-col :span="8">
                  <el-form-item label="所属支行" label-width="75px" size="small">
                    <el-input v-model="approvalFrom.department"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="账号描述" label-width="75px" size="small">
                    <el-input v-model="approvalFrom.acDesc"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  &nbsp;&nbsp;&nbsp;
                  <el-button type="primary" size="small" @click="selAcc">选择账号</el-button>
                </el-col>
              </el-row>

              <el-row>
                <el-col :span="8">
                  <el-row>
                    <el-col :span="12">
                      <el-form-item>
                        <el-checkbox-group v-model="approvalFrom.deal1">
                          <el-checkbox label="短信提醒" name="deal1"></el-checkbox>
                        </el-checkbox-group>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item>
                        <el-checkbox-group v-model="approvalFrom.deal2">
                          <el-checkbox label="优先处理" name="deal2"></el-checkbox>
                        </el-checkbox-group>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-col>
                <el-col :span="8">
                  <el-button size='mini' @click='uploadInAddApprovalDialog' type="primary">附件</el-button>
                </el-col>
              </el-row>

              <!-- 第五行 -->
              <el-row>
                <el-col :span="24">
                  <el-form-item
                    label="审批内容"
                    prop="content"
                    :rules="[{ required: true, message: '请选择输入审批内容',trigger: ['blur','change']}]"
                  >
                    <el-input type="textarea" v-model="approvalFrom.content"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
            <!-- 对话框尾部按钮 -->
            <div slot="footer">
              <el-button @click="closeApprovalDialog">取 消</el-button>
              <el-button
                type="primary"
                @click="clickAddApproval"
                v-show="approvalFrom.addApprovalTitle=='添加审批'"
              >保 存</el-button>
              <el-button
                type="primary"
                @click="clickModiApproval"
                v-show="approvalFrom.addApprovalTitle=='修改审批'"
              >保 存</el-button>
            </div>
          </el-dialog>
          <!-- 审批对话框结束 -->

          <!-- 审批归属对话框开始 -->
          <select-address
            v-if="affiliationDialog"
            :isDialogShow="affiliationDialog"
            :isTitle="affiliationDialogTitle"
            @close="selectRoomDblclick"
          >
          </select-address>
        </div>

        <!-- 设置审批流程对话框 -->
        <div class="set-approve">
          <el-dialog
            title="设置审批流程"
            :visible.sync="setApprovalDialog"
            :close-on-click-modal="false"
            width="800px"
            @close="closeSetApprovalDialog"
          >
            <!-- 下拉框 按钮 -->
            <el-row>
              <el-col :span="8">
                状态：
                <el-select
                  v-model="approvalFlowFrom.selectStatus"
                  style="width:65%;"
                  size="small"
                  @change="flowStateChange"
                >
                  <el-option label="正常" value="1"></el-option>
                  <el-option label="注销" value="0"></el-option>
                </el-select>
              </el-col>
              <el-col :span="4">
                <el-button
                  type="primary"
                  size="mini"
                  @click="addFlow"
                  v-show="approvalFlowStatus"
                >添加流程</el-button>
              </el-col>
              <el-col :span="4">
                <el-button
                  type="primary"
                  size="mini"
                  @click="modifyFlow"
                  v-show="approvalFlowStatus"
                >修改流程</el-button>
              </el-col>
              <el-col :span="4">
                <el-button
                  type="primary"
                  size="mini"
                  @click="logoutFlow"
                  v-show="approvalFlowStatus"
                >注销流程</el-button>
              </el-col>
              <el-col :span="4">
                <el-button
                  type="primary"
                  size="mini"
                  @click="startFlow"
                  v-show="!approvalFlowStatus"
                >启用流程</el-button>
              </el-col>
            </el-row>

            <!-- 数据表 -->
            <el-table
              :data="setApprovalData"
              highlight-current-row
              height="500"
              @row-click="rowSetApprova"
            >
              <el-table-column prop="jeapType" label="审批类型" width="200"></el-table-column>
              <el-table-column prop="approvalFlow" label="审批流程"></el-table-column>
            </el-table>
          </el-dialog>
          <!-- 设置审批流程对话框结束 -->

          <!-- 审批流程 对话框  添加 修改-->
          <el-dialog
            :title="approvalFlowFrom.title"
            :visible.sync="addApprovalFlowDialog"
            :close-on-click-modal="false"
            @close="closeApprovalFlowDialog"
            width="800px"
          >
            <el-form :model="approvalFlowFrom" ref="approvalFlowFrom">
              <el-row :gutter="10">
                <el-col :span="8">
                  <el-form-item
                    prop="type"
                    label="审批类型"
                    label-width="80px"
                    :rules="[{ required: true, message: '请输入审批流程',trigger: 'blur'}]"
                  >
                    <el-input v-model="approvalFlowFrom.type" size="small"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item
                    label="审批流程阶梯数"
                    label-width="125px"
                    prop="number"
                    :rules="[{ required: true, message: '请选择',trigger: 'change'}]"
                  >
                    <el-select
                      v-model="approvalFlowFrom.number"
                      size="small"
                      :disabled="!selectSection"
                      @change="selectSteps"
                    >
                      <el-option label="1" value="1"></el-option>
                      <el-option label="2" value="2"></el-option>
                      <el-option label="3" value="3"></el-option>
                      <el-option label="4" value="4"></el-option>
                      <el-option label="5" value="5"></el-option>
                      <el-option label="6" value="6"></el-option>
                      <el-option label="7" value="7"></el-option>
                      <el-option label="8" value="8"></el-option>
                      <el-option label="9" value="9"></el-option>
                      <el-option label="10" value="10"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item>
                    流程类型
                    <el-select
                      v-model="approvalFlowFrom.flowType"
                      @change="flowTypeChange"
                      style="width:65%;"
                      size="small"
                    >
                      <el-option label="按部门" value="按部门"></el-option>
                      <el-option label="按岗位" value="按岗位"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row v-if="selectSection">
                <el-row v-for="(item,index) in approvalFlowFrom.steps" :key="index" :gutter="10">
                  <el-col :span="8">
                    <el-form-item>
                      阶梯编号
                      <el-input v-model="item.number" style="width:65%;" size="small" disabled></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item
                      label="审批人"
                      label-width="75px"
                      :prop="'steps.' + index + '.approver'"
                      :rules="[{ required: true, message: '请选择审批人',trigger: ['blur', 'change']}]"
                    >
                      <el-cascader
                        v-model="item.approver"
                        :options="userNameList"
                        :props="{ expandTrigger: 'hover', checkStrictly: true}"
                        :show-all-levels="false"
                        size="small"
                        clearable
                        filterable
                      ></el-cascader>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-row>
              <el-row v-if="!selectSection">
                <el-col :span="8">
                  <el-form-item>
                    阶梯编号
                    <el-input
                      disabled
                      v-model="approvalFlowFrom.number2"
                      style="width: 65%;"
                      size="small"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item
                    label="审批人"
                    label-width="115px"
                    prop="section"
                    :rules="[{ required: true, message: '请选择',trigger: 'change'}]"
                  >
                    <el-select
                      v-model="approvalFlowFrom.section"
                      size="small"
                    >
                      <el-option label=" " value></el-option>
                      <el-option
                        v-for="(item,index) in approvalFlowFrom.sectionOpt"
                        :key="index"
                        :label="item.variable"
                        :value="item.variable"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="审批内容模板">
                    <el-input type="textarea" v-model="approvalFlowFrom.template"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>

            <div slot="footer">
              <el-button @click="addApprovalFlowDialog = false">取 消</el-button>
              <el-button
                type="primary"
                @click="clickAddFlow"
                v-show="approvalFlowFrom.title == '添加审批流程'"
              >保 存</el-button>
              <el-button
                type="primary"
                @click="clickModiFlow"
                v-show="approvalFlowFrom.title == '修改审批流程'"
              >保 存</el-button>
            </div>
          </el-dialog>
        </div>
      </el-row>
      <!-- 表格筛选 -->
      <el-row class="screen">
        <el-form inline label-width="70px">
          <el-row>
            <el-form-item label="门店">
              <el-input v-model="addCommunity" clearable size="mini" style="width:100px" @change="checkChange"></el-input>
            </el-form-item>
            <el-form-item label="房型">
              <el-select v-model="addRoomType" @change="checkChange" filterable clearable size="mini" style="width:100px">
                <el-option v-for="(value,i) in JSON.parse(setupList.jsrsuRoomType)" :key="i" :label="value.roomType" :value="value.roomType"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="门牌号">
              <el-input v-model="addDoorplateno" clearable size="mini" style="width:100px" @change="checkChange"></el-input>
            </el-form-item>
            <el-form-item label="审批状态">
              <el-select v-model="scState" @change="scBlur" size="mini" style="width:100px">
                <el-option label="全部" value></el-option>
                <el-option label="处理中" value="处理中"></el-option>
                <el-option label="已完成" value="已完成"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="申请日期">
              <el-date-picker
                v-model="scTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                @input="checkChange"
                size="mini"
              ></el-date-picker>
            </el-form-item>
          </el-row>
          <el-row v-show="check">
            <el-form-item label="申请人">
              <v-cascader
                :options="userNameList"
                v-model.lazy="scName"
                @change="scBlur"
                :strictly="true"
                filterable
                clearable
                :props="{ expandTrigger: 'hover' }"
                size="mini"
                style="width:100px"
              ></v-cascader>
            </el-form-item>
            <el-form-item label="处理人">
              <v-cascader
                :options="userNameList"
                v-model.lazy="scApprover"
                @change="scBlur"
                :strictly="true"
                filterable
                clearable
                :props="{ expandTrigger: 'hover' }"
                size="mini"
                style="width:100px"
              ></v-cascader>
            </el-form-item>
            <el-form-item label="归属类型">
              <el-select v-model="scType" @change="scBlur" size="mini" style="width:100px">
                <el-option label="全部" value></el-option>
                <el-option label="其他审批" value="其他审批"></el-option>
                <el-option label="房间审批" value="房间审批"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="关键词">
              <el-input
                clearable
                v-model="eaEventContent"
                @change="checkChange"
                size="mini"
                style="width:100px"
              ></el-input>
            </el-form-item>
          </el-row>
        </el-form>
      </el-row>
    </div>
    <!-- 数据表 -->
    <div style="text-align:center;">
      <el-table
        ref="approvalTable"
        :data="approvalData"
        v-loading="dataloading"
        style="width: 100%"
        :height="tableHeight"
        highlight-current-row
        @row-click="rowClick"
        @row-dblclick="dbRow"
        :row-class-name="tableRowClassName"
      >
        <el-table-column align="center" prop="eaReleaseTime" label="申请日期" min-width="140px"></el-table-column>
        <el-table-column align="center" prop="publisherName" label="申请人"></el-table-column>
        <el-table-column align="center" prop="handlerName" label="处理人"></el-table-column>
        <el-table-column align="center" prop="detailAddress" label="归属地址" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="eaEventContent" label="审批内容" min-width="140px" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="eaEventType" label="审批类型" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="eaEventState" label="审批状态"></el-table-column>
        <el-table-column align="center" prop="totalPage" label="审批耗时"></el-table-column>
        <el-table-column align="center" prop="eaWhetherGenerateRecord" label="是否生成收支"></el-table-column>
        <el-table-column align="center" prop="eaCompletionTime" label="完成时间" show-overflow-tooltip></el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        :total="total"
        :page-size="pageSize"
        :page-sizes="[5,10,20,40]"
        :current-page="currentPage"
        layout="total, sizes, prev, pager, next, jumper"
        background
        @size-change="sizeChange"
        @current-change="currentChange"
      ></el-pagination>
    </div>
    <!-- 详细 -->
    <el-dialog
      title="审批详细"
      :visible.sync="approvalDetailDialog"
      :close-on-click-modal="false"
      width="800px"
    >
      <div class="project-detail">
        <div class="describe">
          <el-row>
            <el-col :span="12">
              <span>归属地址：</span>
              {{detailData.detailAddress}}
            </el-col>
            <el-col :span="12">
              <span>审批编号：</span>
              {{detailData.eaApprovalNumber}}
            </el-col>
          </el-row>
        </div>
        <div class="describe">
          <el-row>
            <el-col :span="12">
              <span>申请人：</span>
              {{detailData.publisherName}}
            </el-col>
            <el-col :span="12">
              <span>申请时间：</span>
              {{detailData.eaReleaseTime}}
            </el-col>
          </el-row>
        </div>
        <div class="describe">
          <el-row>
            <el-col :span="12">
              <span>涉及金额：</span>
              ￥：{{detailData.eaAmountInvolved}}元
            </el-col>
            <el-col :span="12">
              <span>所属支行：</span>
              {{detailData.eaSubordinateBranch}}
            </el-col>
          </el-row>
        </div>
        <div class="describe">
          <el-row>
            <el-col :span="24">
              <span>申请内容：</span>
              {{detailData.eaEventContent}}
            </el-col>
          </el-row>
        </div>
        <div class="describe">
          <el-row>
            <el-col :span="24">
              <span>账号描述：</span>
              {{detailData.eaBankAccountDescription}}
            </el-col>
          </el-row>
        </div>
      </div>
      <p style="line-height:26px;font-size:18px;margin:0;padding-top:10px">审批节点</p>
      <el-tabs v-model="detailTab">
        <!-- <el-tab-pane label="审批节点" name="approval"> -->
          <el-table :data="approvalBill" height="250" highlight-current-row>
            <el-table-column prop="name" label="审批人"></el-table-column>
            <el-table-column prop="advise" label="审批意见"></el-table-column>
            <el-table-column prop="time" label="审批时间"></el-table-column>
          </el-table>
        <!-- </el-tab-pane> -->
        <div style="text-align: center;marginTop:10px">
          <el-button type="primary" size="mini" @click="next(false)">上一条</el-button>
          <el-button
            type="primary"
            size="mini"
            v-show="detailData.eaEventHandler==currentUserInfo.userCoding && detailData.eaEventState == '处理中'"
            @click="clickApproval"
          >审批</el-button>
          <el-button
            type="primary"
            v-show="detailData.eaEventHandler==currentUserInfo.userCoding && detailData.eaEventState == '处理中' && detailData.eaAmountInvolved != 0 && detailData.eaWhetherGenerateRecord == '否'"
            @click="clickIncExp"
            size="mini"
          >付款</el-button>
          <el-button type="primary" size="mini" @click="clickPrint">打印审批单</el-button>
          <el-button type="primary" size="mini" @click="next(true)">下一条</el-button>
        </div>
      </el-tabs>
    </el-dialog>

    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" />
    </el-dialog>
    <!-- 审批处理 -->
    <el-dialog
      :visible.sync="dealDialog"
      title="处理意见"
      @close="closeDealDialog"
      :close-on-click-modal="false"
    >
      <el-form ref="dealform" :model="dealform">
            <el-form-item label="审批状态" label-width="110px">
              <el-select v-model="dealform.state" size="small">
                <el-option
                  label="处理中"
                  value="处理中"
                  v-show="dealform.approver.length != 0 || detailData.eaEventType == '自由流程'"
                ></el-option>
                <el-option
                  label="已完成"
                  value="已完成"
                  v-show="dealform.approver.length == 0 || detailData.eaEventType == '自由流程'"
                ></el-option>
                <el-option label="已作废" value="已作废"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="涉及金额"
              v-show="detailData.eaEventHandler==currentUserInfo.userCoding && detailData.eaEventState == '处理中' && detailData.eaAmountInvolved != 0 && detailData.eaWhetherGenerateRecord == '否'"
              label-width="70px"
            >
              <el-input v-model="dealform.money" size="small"></el-input>
            </el-form-item>
        <el-form-item
          v-show="dealform.state == '处理中'"
          label="下一步审批人"
          label-width="110px"
          prop="approver"
          :rules="[{ required: true, message: '请选择审批人',trigger: ['blur','change']}]"
        >
          <el-cascader
            placeholder=""
            v-model="dealform.approver"
            :options="userNameList"
            :props="{ expandTrigger: 'hover'}"
            size="small"
            clearable
            filterable
            :disabled="detailData.eaEventType != '自由流程'"
          ></el-cascader>
        </el-form-item>
        <el-form-item v-if="dealform.state == '处理中'">
          <el-checkbox-group v-model="dealform.topic" size="samll">
            <el-checkbox label="是否短信提醒下一步审批人" name="topic" border></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item v-else>
          <el-checkbox-group v-model="dealform.topic" size="samll">
            <el-checkbox label="是否短信提醒发布人" name="topic" border></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-row>
          <el-form-item>
            <el-button type="primary" @click="uploadInDealDialog">附件</el-button>
          </el-form-item>
        </el-row>
        <el-form-item label="审批意见">
          <el-input type="textarea" v-model="dealform.opinion"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dealDialog = false">取 消</el-button>
        <el-button type="primary" @click="dealApproval">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 打印 -->
    <el-dialog
      title="打印页面"
      :visible.sync="printDialog"
      width="830px"
      size="small"
      :close-on-click-modal="false"
    >
      <!-- <img  id="printTest" :src="printImg" style="width:100%;  height:auto;" > -->
      <div id="printTest">
        <div>
          <div id="title">
            智慧酒店系统
            <br />
            {{detailData.eaEventType}}审批单
          </div>
          <div style="float: left;margin-left: 1%;">归属地址：{{detailData.detailAddress}}</div>
          <div style="float: right;margin-right: 1%;">审批编号:{{detailData.eaApprovalNumber}}</div>
        </div>
        <table align="center" cellspacing="0" style="margin-top:10px;border-top: 1px solid #888;">
          <tbody>
            <tr align="center">
              <td width="20%">申请人</td>
              <td width="30%">{{detailData.publisherName}}</td>
              <td width="20%">申请时间</td>
              <td width="30%">{{detailData.eaReleaseTime}}</td>
            </tr>
          </tbody>
        </table>
        <table align="center" cellspacing="0" style="border-top: none;">
          <tbody>
            <tr align="center">
              <td width="20%" height="50px">申请内容</td>
              <td width="80%">
                <div
                  style="white-space:normal;word-break:break-all;word-wrap:break-word;"
                >{{detailData.eaEventContent}}</div>
              </td>
            </tr>
          </tbody>
        </table>
        <table align="center" cellspacing="0" style="margin-top: 1%;border-top: 1px solid #888;">
          <tbody>
            <tr align="center">
              <td width="20%">审批节点</td>
              <td width="20%">审批人</td>
              <td width="40%">审批意见</td>
              <td width="20%">审批时间</td>
            </tr>
            <tr align="center" v-for="(item,index) in detailData.eaTreatmentOpinion" :key="index">
              <td width="20%"></td>
              <td width="20%">{{item.name}}</td>
              <td
                width="40%"
                style="white-space:normal;word-break:break-all;word-wrap:break-word;"
              >{{item.advise}}</td>
              <td width="20%">{{item.time}}</td>
            </tr>
            <tr align="center" v-for="count in detailData.count" :key="count+'1'">
              <td width="20%">-</td>
              <td width="20%"></td>
              <td width="40%"></td>
              <td width="20%"></td>
            </tr>
          </tbody>
        </table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="printDialog = false">取 消</el-button>
        <el-button type="primary" v-print="'#printTest'">去打印</el-button>
      </span>
    </el-dialog>
    <!-- 手机上传 -->
    <el-dialog
      :visible.sync="phoneUploadDia"
      title="微信扫一扫上传"
      width="280px"
      :close-on-click-modal="false"
      @close="closePhoneUploadDia"
    >
      <el-row type="flex" class="row-bg" justify="space-around">
        <el-col :span="24">
          <canvas id="QRCode"></canvas>
        </el-col>
      </el-row>
    </el-dialog>
    <!-- 银行账号对话框 -->
    <el-dialog
      title="银行账号"
      :visible.sync="selBankAccDia"
      width="800px"
      :close-on-click-modal="false"
    >
      <el-form label-width="80px">
        <el-row :gutter="5">
          <el-col :span="8">
            <el-form-item label="账号名称">
              <el-input v-model="scAccName" @input="scBankChange" size="small"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="开户银行">
              <el-input v-model="scBank" @input="scBankChange" size="small"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-table :data="bankAccData" style="width: 100%" height="400px" @row-dblclick="dbSelAcc">
        <el-table-column prop="csAccountName" label="账号名称"></el-table-column>
        <el-table-column prop="csBank" label="开户银行"></el-table-column>
        <el-table-column prop="csAccountUmber" label="银行账号"></el-table-column>
        <el-table-column prop="csSubordinateBranch" label="所属账号"></el-table-column>
        <el-table-column prop="csAccountDescription" label="账号描述"></el-table-column>
      </el-table>
      <el-pagination
        :total="total2"
        :page-size="pageSize2"
        :page-sizes="[5,10,20,40]"
        :current-page="currentPage2"
        layout="total, sizes, prev, pager, next, jumper"
        background
        @size-change="sizeChange2"
        @current-change="currentChange2"
      ></el-pagination>
    </el-dialog>
    <!-- 收支对话框 -->
    <el-dialog title="新增收支" :visible.sync="incExpDia" @close="incExpDiaClose" width="900px" :close-on-click-modal="false">
      <!-- <el-divider content-position="left">收支归属</el-divider> -->
      <el-form ref="incExpFrom" :model="incExpFrom" label-width="75px" :rules="incExpFromRules" size="small">
        <el-row :gutter="5">
          <el-col :span="8">
            <el-form-item label="归属类型">
              <el-select v-model="incExpFrom.affType">
                <el-option label="其他类" value="其他类"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="归属名称">
              <el-input v-model="incExpFrom.affName" readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="记账日期">
              <el-date-picker
                v-model="incExpFrom.accDate"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                type="date"
                :clearable="false"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="5">
          <el-col :span="16">
            <el-form-item label="费用归属">
              <el-input v-model="incExpFrom.moneyAff" readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="经手人" prop="handler">
              <el-cascader
                placeholder=""
                v-model="incExpFrom.handler"
                :options="userNameList"
                :props="{ expandTrigger: 'hover'}"
                size="small"
                clearable
                filterable
              ></el-cascader>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="5">
          <el-col :span="8">
            <el-form-item label="账号类型" prop="accType">
              <el-select v-model="incExpFrom.accType" @change="selAccType">
                <el-option
                  v-for="(item, i) in incExpFrom.accTypeOpt"
                  :key="i"
                  :label="item.variable"
                  :value="item.variable"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="账号名称" prop="accName">
              <el-select v-model="incExpFrom.accName" @change="selAccName">
                <el-option
                  v-for="(item, i) in incExpFrom.accNameOpt"
                  :key="i"
                  :label="item.faUserName"
                  :value="item.faId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="收支方式" prop="accMethod">
              <el-select v-model="incExpFrom.accMethod">
                <el-option label value></el-option>
                <el-option label="现钞" value="现钞"></el-option>
                <el-option label="转账" value="转账"></el-option>
                <el-option label="刷卡" value="刷卡"></el-option>
                <el-option label="支票" value="支票"></el-option>
                <el-option label="在线支付" value="在线支付"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="5">
          <el-col :span="8">
            <el-form-item label="账户号码">
              <el-input v-model="incExpFrom.account" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="账号归属">
              <el-input v-model="incExpFrom.accAff" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <el-button @click="uploadInIncExpDia" type="primary" size="small">附件</el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="5">
          <el-col :span="6">
            <el-form-item label="收支性质">
              <el-input v-model="incExpFrom.nature" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="收支分类">
              <el-input v-model="incExpFrom.incExpClas" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="收支种类">
              <el-input v-model="incExpFrom.incExpKind" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6" size="small">
            <el-form-item label="选择分类" prop="selSort">
              <el-cascader
                :show-all-levels="false"
                placeholder=""
                v-model="incExpFrom.selSort"
                :options="selectionSort"
                :props="{ expandTrigger: 'hover'}"
                size="small"
                clearable
                filterable
                @change="selClas"
              ></el-cascader>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="5">
          <el-col :span="6">
            <el-form-item label="收支金额">
              <el-input v-model="incExpFrom.money"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="票据编号">
              <el-input v-model="incExpFrom.number" readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="归属周期" prop="period">
              <el-date-picker
                v-model="incExpFrom.period"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="5">
          <el-col :span="24">
            <el-form-item label="收支原因">
              <el-input
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 4}"
                placeholder="系统已自动将申请内容作为收支原因，请根据需要继续输入..."
                v-model="incExpFrom.season"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button type="primary" @click="generateBills(1)">生成账单</el-button>
        <el-button type="primary" @click="generateBills(2)">生成账单并完成审批</el-button>
        <el-button @click="incExpDia = false">关闭</el-button>
      </div>
    </el-dialog>
    <!-- 图片上传组件 -->
    <common-upload ref='upload' @listenEvent='dialogImgPathClose' :dialog="dialogImgPath" :save='needSavePhoto' :photoid='photoTableId' :photorandom='photoRandom' :phototype='photoTableType' ></common-upload>
  </div>
</template>

<script>
import { taskCostTime, approvalNumber } from "../../assets/utils";
import { mapState } from "vuex";
import QRCode from "qrcode"; //引入生成二维码插件
import commonUpload from '../../components/common-upload'
import selectAddress from '../../components/selectAddress/selectAddress'
import moment from 'moment';
export default {
  components: {
    'common-upload':commonUpload,
    'select-address':selectAddress,
  },
  data() {
    let periodVer = (rule, value, callback) => {
      console.log(value)
      if (!value) {
        console.log(1)
      } else {
        console.log(2)
      }
      if (!value) {
        callback(new Error('归属周期不能为空'))
      }
    }
    return {
      dataloading:false,
      eaEventContent: "",
      scTime: [],
      check: false, //高级筛选显示判断

      addApprovalDialog: false,
      affiliationDialogTitle: false,
      affiliationDialog: false,
      dialogVisible: false,

      setApprovalDialog: false,
      addApprovalFlowDialog: false,

      approvalDetailDialog: false,
      accDialog: false,
      dealDialog: false,

      printDialog: false,
      phoneUploadDia: false,
      selBankAccDia: false,
      incExpDia: false,
      incExpFrom: {
        affType: "",//归属类型
        affName: "",//归属名称
        accDate: "",//记账日期
        moneyAff: "",//费用归属
        handler: "",//经手人

        accType: "",//账号类型
        accTypeOpt: [],//账号类型数组
        accName: "",//账号名称
        accNameOpt: [],//账号名称数组
        accMethod: "",//收支方式

        account: "",//账户号码
        accAff: "",//账号归属
        nature: "",//收支性质
        incExpClas: "",//收支分类
        incExpKind: "",//收支种类
        
        selSort: "",//选择分类
        money: "",//收支金额
        number: "",//票据编号
        period: null,//归属周期
        season: "",//收支原因
      },
      incExpFromRules: {
        handler: [
          {required: true,message: '经手人不能为空',trigger: 'change',},
        ],
        accType: [
          {required: true,message: '账号名称不能为空',trigger: 'change',},
        ],
        accName: [
          {required: true,message: '账号名称不能为空',trigger: 'change',},
        ],
        accMethod: [
          {required: true,message: '收支方式不能为空',trigger: 'change',},
        ],
        selSort: [
          {required: true,message: '选择分类不能为空',trigger: 'change',},
        ],
        period: [
          {validator: periodVer,trigger: 'change' },
        ],
      },
      approvalFromRef: ["approvalFrom"],
      approvalFrom: {
        addApprovalTitle: "",
        affiliation: "",
        eaHouseId: "",
        eaCocId: "",
        eaHomeType: "",
        approver: "",
        approver2: "",
        approver2Opt: [],
        type: "",
        typeOptions: [],
        number: "",
        describe: "",
        content: "",
        money: 0,
        acName: "",
        depBank: "",
        bankAc: "",
        department: "",
        acDesc: "",
        deal1: "",
        deal2: "",
        imgUploadList: [],
        demoOpt: [],

        eaId: "",
        publisherName: "",

        associationTypes: "办公室列表", //关联类型
        clientScName: "",
        hsAddCommunity: "",
        hsHouseNote: "",

        otherListData: [],
        clientListData: [],
        affTotal: 0,
        affPageSize: 5,
        affCur: 1
      },
      isPost: false,
      isArea: false,

      QRCodeMsg: "",

      picData: {
        token: "",
        "x:co": "",
        "x:filename": "",
        "x:photoRandom": "", //十二位随机数
        "x:photoTableType": "", //审批or任务
        "x:photoMimeType": "", //文件or图片
        "x:photoBucket": "" //公用or私有
      },
      dialogImageUrl: "",

      addCommunity: "",
      addRoomType: "",
      addDoorplateno: "",
      scName: [],
      scApprover: [],
      scType: "",
      scAccName: "",
      scBank: "",

      scState: "处理中",
      approvalData: [],
      bankAccData: [],

      approvalFlowformRef: ["approvalFlowFrom"],
      approvalFlowFrom: {
        title: "",
        selectStatus: "1",
        type: "",
        number: "",
        number2: "1",
        flowType: "按部门",
        template: "",
        section: "",
        steps: [],
        sectionOpt: []
      },
      setApprovalData: [],

      tableHeight: window.innerHeight - 240,
      clickrow: "",
      clickSetRow: "",

      detailTab: "approval",
      approvalBill: [],
      dealform: {
        state: "处理中",
        approver: "",
        handlerId: "",
        money: "",
        topic: "",
        opinion: "",
        topic2: "",
        nextApr: [],
        imgUploadList: [],
        imgDelId: []
      },
      detailData: "",

      total: 0,
      pageSize: 10,
      currentPage: 1,
      total2: 0,
      pageSize2: 10,
      currentPage2: 1,
      //上传图片
      dialogImgPath:false,
      needSavePhoto:false,
      photoTableId:'',
      photoTableType:'',
      photoRandom:'',

      noticeRow:{}
    };
  },
  created() {
    let data = this.currentUserInfo;
    this.scApprover = [
      data.storefrontId.toString(),
      data.departmentId.toString(),
      data.userCoding.toString()
    ];

    this.getApprovalData();
  },
  mounted(){
    this.$nextTick(() => {
      this.tableHeight = window.innerHeight - this.$refs.screen.clientHeight - 140
    })
  },
  computed: {
    ...mapState(['setupList',"userNameList", "userName", "currentUserInfo"]),
    approvalFlowStatus() {
      return this.approvalFlowFrom.selectStatus == "1" ? true : false;
    },
    selectSection() {
      return this.approvalFlowFrom.flowType == "按部门" ? true : false;
    },
    approvalTypeDis() {
      return this.approvalFrom.type != "自由流程";
    },
    isMore() {
      return this.approvalFrom.money != 0;
    },
    selectionSort() {
      return [
        {
          label: "收入",
          value: "收入",
          children: [
            {
              label: "主营类",
              value: "主营类",
              children: [
                {
                  label: "租金",
                  value: "租金"
                },
                {
                  label: "门卡工本费",
                  value: "门卡工本费"
                },
                {
                  label: "佣金服务费",
                  value: "佣金服务费"
                },
                {
                  label: "车位租金",
                  value: "车位租金"
                },
                {
                  label: "租赁管理费",
                  value: "租赁管理费"
                },
                {
                  label: "商超费用",
                  value: "商超费用"
                },
                {
                  label: "车位挂牌费",
                  value: "车位挂牌费"
                }
              ]
            },
            {
              label: "能源类",
              value: "能源类",
              children: [
                {
                  label: "水费",
                  value: "水费"
                },
                {
                  label: "电费",
                  value: "电费"
                },
                {
                  label: "燃气费",
                  value: "燃气费"
                },
                {
                  label: "网络费",
                  value: "网络费"
                },
                {
                  label: "电视",
                  value: "电视"
                },
                {
                  label: "物业管理费",
                  value: "物业管理费"
                }
              ]
            },
            {
              label: "押金类",
              value: "押金类",
              children: [
                {
                  label: "房屋押金",
                  value: "房屋押金"
                },
                {
                  label: "水电押金",
                  value: "水电押金"
                },
                {
                  label: "门卡押金",
                  value: "门卡押金"
                },
                {
                  label: "信箱钥匙押金",
                  value: "信箱钥匙押金"
                },
                {
                  label: "业主退房押金",
                  value: "业主退房押金"
                },
                {
                  label: "装修押金",
                  value: "装修押金"
                },
                {
                  label: "其他押金",
                  value: "其他押金"
                },
                {
                  label: "定金",
                  value: "定金"
                }
              ]
            },
            {
              label: "维修类",
              value: "维修类",
              children: [
                {
                  label: "内部-材料费",
                  value: "内部-材料费"
                },
                {
                  label: "内部-人工费",
                  value: "内部-人工费"
                },
                {
                  label: "保洁费",
                  value: "保洁费"
                },
                {
                  label: "装修预收款",
                  value: "装修预收款"
                },
                {
                  label: "换锁",
                  value: "换锁"
                },
                {
                  label: "退房-材料费",
                  value: "退房-材料费"
                }
              ]
            },
            {
              label: "违约类",
              value: "违约类",
              children: [
                {
                  label: "逾期-违约金",
                  value: "逾期-违约金"
                },
                {
                  label: "退房-违约金",
                  value: "退房-违约金"
                },
                {
                  label: "代缴手续费",
                  value: "代缴手续费"
                },
                {
                  label: "转租费用",
                  value: "转租费用"
                }
              ]
            },
            {
              label: "商超类",
              value: "商超类",
              children: [
                {
                  label: "商品销售",
                  value: "商品销售"
                },
                {
                  label: "采购退货",
                  value: "采购退货"
                }
              ]
            },
            {
              label: "财务类",
              value: "财务类",
              children: [
                {
                  label: "税费",
                  value: "税费"
                },
                {
                  label: "废品折扣",
                  value: "废品折扣"
                },
                {
                  label: "POS手续费",
                  value: "POS手续费"
                },
                {
                  label: "财务费用",
                  value: "财务费用"
                },
                {
                  label: "资金调配",
                  value: "资金调配"
                }
              ]
            },
            {
              label: "债务类",
              value: "债务类",
              children: [
                {
                  label: "借入款",
                  value: "借入款"
                },
                {
                  label: "基金=罚款",
                  value: "基金=罚款"
                }
              ]
            },
            {
              label: "债权类",
              value: "债权类",
              children: [
                {
                  label: "应收贷款",
                  value: "应收贷款"
                },
                {
                  label: "应收利息款",
                  value: "应收利息款"
                }
              ]
            },
            {
              label: "税费类",
              value: "税费类",
              children: [
                {
                  label: "增值税",
                  value: "增值税"
                },
                {
                  label: "税费",
                  value: "税费"
                }
              ]
            },
            {
              label: "其他类",
              value: "其他类",
              children: [
                {
                  label: "代缴费用",
                  value: "代缴费用"
                },
                {
                  label: "转账",
                  value: "转账"
                },
                {
                  label: "手续费",
                  value: "手续费"
                },
                {
                  label: "校准平账",
                  value: "校准平账"
                }
              ]
            },
            {
              label: "欠结类",
              value: "欠结类",
              children: [
                {
                  label: "租客预存款",
                  value: "租客预存款"
                },
                {
                  label: "租客还欠结款",
                  value: "租客还欠结款"
                },
                {
                  label: "待付房东款",
                  value: "待付房东款"
                },
                {
                  label: "房东还欠结款",
                  value: "房东还欠结款"
                }
              ]
            }
          ]
        },
        {
          label: "支出",
          value: "支出",
          children: [
            {
              label: "主营类",
              value: "主营类",
              children: [
                {
                  label: "租金",
                  value: "租金"
                },
                {
                  label: "佣金服务费",
                  value: "佣金服务费"
                },
                {
                  label: "车位租金",
                  value: "车位租金"
                },
                {
                  label: "车位管理费",
                  value: "车位管理费"
                }
              ]
            },
            {
              label: "能源类",
              value: "能源类",
              children: [
                {
                  label: "水费",
                  value: "水费"
                },
                {
                  label: "电费",
                  value: "电费"
                },
                {
                  label: "燃气费",
                  value: "燃气费"
                },
                {
                  label: "网络费",
                  value: "网络费"
                },
                {
                  label: "电视",
                  value: "电视"
                },
                {
                  label: "物业管理费",
                  value: "物业管理费"
                },
                {
                  label: "余款结算",
                  value: "余款结算"
                }
              ]
            },
            {
              label: "押金类",
              value: "押金类",
              children: [
                {
                  label: "房屋押金",
                  value: "房屋押金"
                },
                {
                  label: "水电押金",
                  value: "水电押金"
                },
                {
                  label: "门卡押金",
                  value: "门卡押金"
                },
                {
                  label: "车卡押金",
                  value: "车卡押金"
                },
                {
                  label: "租户退房押金",
                  value: "租户退房押金"
                },
                {
                  label: "定金",
                  value: "定金"
                },
                {
                  label: "装修押金",
                  value: "装修押金"
                },
                {
                  label: "代购款",
                  value: "代购款"
                }
              ]
            },
            {
              label: "维修类",
              value: "维修类",
              children: [
                {
                  label: "材料费",
                  value: "材料费"
                },
                {
                  label: "人工费",
                  value: "人工费"
                },
                {
                  label: "保洁费",
                  value: "保洁费"
                },
                {
                  label: "装修预收费",
                  value: "装修预收费"
                },
                {
                  label: "换锁",
                  value: "换锁"
                }
              ]
            },
            {
              label: "薪酬福利类",
              value: "薪酬福利类",
              children: [
                {
                  label: "工资",
                  value: "工资"
                },
                {
                  label: "提成",
                  value: "提成"
                },
                {
                  label: "生活费",
                  value: "生活费"
                },
                {
                  label: "社保",
                  value: "社保"
                },
                {
                  label: "职工福利费",
                  value: "职工福利费"
                }
              ]
            },
            {
              label: "违约类",
              value: "违约类",
              children: [
                {
                  label: "违约金",
                  value: "违约金"
                },
                {
                  label: "滞纳金",
                  value: "滞纳金"
                }
              ]
            },
            {
              label: "商超类",
              value: "商超类",
              children: [
                {
                  label: "商品采购",
                  value: "商品采购"
                },
                {
                  label: "商品退货",
                  value: "商品退货"
                }
              ]
            },
            {
              label: "财务类",
              value: "财务类",
              children: [
                {
                  label: "记账费用",
                  value: "记账费用"
                },
                {
                  label: "税费",
                  value: "税费"
                },

                {
                  label: "POS运营费",
                  value: "POS运营费"
                },
                {
                  label: "财务费用",
                  value: "财务费用"
                },
                {
                  label: "资金调配",
                  value: "资金调配"
                },
                {
                  label: "应收款",
                  value: "应收款"
                }
              ]
            },
            {
              label: "办公成本类",
              value: "办公成本类",
              children: [
                {
                  label: "办公室租金",
                  value: "办公室租金"
                },
                {
                  label: "水费",
                  value: "水费"
                },
                {
                  label: "电费",
                  value: "电费"
                },
                {
                  label: "气费",
                  value: "气费"
                },
                {
                  label: "桶装水",
                  value: "桶装水"
                },
                {
                  label: "办公费用",
                  value: "办公费用"
                },
                {
                  label: "电话费用",
                  value: "电话费用"
                },
                {
                  label: "报销费",
                  value: "报销费"
                }
              ]
            },
            {
              label: "设备采购类",
              value: "设备采购类",
              children: [
                {
                  label: "家私费",
                  value: "家私费"
                },
                {
                  label: "电器费",
                  value: "电器费"
                }
              ]
            },
            {
              label: "债务类",
              value: "债务类",
              children: [
                {
                  label: "应还款",
                  value: "应还款"
                },
                {
                  label: "应还利息款",
                  value: "应还利息款"
                }
              ]
            },
            {
              label: "债权类",
              value: "债权类",
              children: [
                {
                  label: "贷出款",
                  value: "贷出款"
                },
                {
                  label: "内部借款",
                  value: "内部借款"
                }
              ]
            },
            {
              label: "税费类",
              value: "税费类",
              children: [
                {
                  label: "税费",
                  value: "税费"
                }
              ]
            },
            {
              label: "分红类",
              value: "分红类",
              children: [
                {
                  label: "股东分红",
                  value: "股东分红"
                }
              ]
            },
            {
              label: "其他类",
              value: "其他类",
              children: [
                {
                  label: "代缴费用",
                  value: "代缴费用"
                },
                {
                  label: "转账",
                  value: "转账"
                },
                {
                  label: "广告费",
                  value: "广告费"
                },
                {
                  label: "公关费",
                  value: "公关费"
                },
                {
                  label: "手续费",
                  value: "手续费"
                },
                {
                  label: "校准平账",
                  value: "校准平账"
                }
              ]
            },
            {
              label: "欠结类",
              value: "欠结类",
              children: [
                {
                  label: "租客欠结款",
                  value: "租客欠结款"
                },
                {
                  label: "还租客预存款",
                  value: "还租客预存款"
                },
                {
                  label: "支付房东欠结款",
                  value: "支付房东欠结款"
                },
                {
                  label: "房东欠结款",
                  value: "房东欠结款"
                },
                {
                  label: "充值优惠卷",
                  value: "充值优惠卷"
                }
              ]
            }
          ]
        }
      ];
    }
  },
  watch: {
    // 通过监听获取数据
    QRCodeMsg(val) {
      // 获取页面的canvas
      var msg = document.getElementById("QRCode");
      // 将获取到的数据（val）画到msg（canvas）上
      QRCode.toCanvas(msg, val, function(error) {
        console.log(error);
      });
    }
  },
  methods: {
    //高级筛选显示/隐藏
    advancedScreening(){//高级筛选
      this.check = !this.check;
      this.$nextTick(() => {
        this.tableHeight = window.innerHeight - this.$refs.screen.clientHeight - 140
      })
    },
    //高级筛选
    checkChange() {
      this.getApprovalData();
    },
    //获取银行账号列表
    getBankAccData() {
      this.$axios({
        url: `/common1/accountReceipt`,
        method: "get",
        params: {
          csAccountName: this.scAccName,
          csBank: this.scBank,
          cursor: this.currentPage2,
          limit: this.pageSize2
        }
      }).then(res => {
        this.bankAccData = res.data.result.records;
        this.total2 = res.data.result.total;
      }).catch(err => {
        this.isError(err,this)
      })
    },
    //获取首页审批数据
    getApprovalData() {
      this.dataloading = true
      let publisherStoreId = ''
      let publisherDetId = ''
      let eaEventPublisher = ''
      if (this.scName.length == 1) {
        publisherStoreId = this.scName[0]
      } else if (this.scName.length == 2) {
        publisherDetId = this.scName[1]
      } else if (this.scName.length == 3) {
        eaEventPublisher = this.scName[2]
      }
      let handlerStoreId = ''
      let handlerDetId = ''
      let eaEventHandler = ''
      if (this.scApprover.length == 1) {
        handlerStoreId = this.scApprover[0]
      } else if (this.scApprover.length == 2) {
        handlerDetId = this.scApprover[1]
      } else if (this.scApprover.length == 3) {
        eaEventHandler = this.scApprover[2]
      }
      this.$axios({
        url: "/common1/approvalPapers",
        method: "get",
        params: {
          limit: this.pageSize,
          cursor: this.currentPage,
          addCommunity: this.addCommunity,
          addRoomType: this.addRoomType,
          addDoorplateno: this.addDoorplateno,

          publisherStoreId: publisherStoreId,//申请人门店id
          publisherDetId: publisherDetId,//申请人部门id
          eaEventPublisher: eaEventPublisher,//申请人id

          handlerStoreId: handlerStoreId,//处理人门店id
          handlerDetId: handlerDetId,//处理人部门id
          eaEventHandler: eaEventHandler,//处理人id
          
          eaHomeType: this.scType,
          eaEventState: this.scState,
          eaEventContent: this.eaEventContent,
          startTime: this.scTime == null ? "" : this.scTime[0],
          endTime: this.scTime== null ? "" : this.scTime[1]
        }
      }).then(res => {
        this.total = res.data.result.total;
        this.approvalData = res.data.result.records;
        let data = this.approvalData;
        for (let i of data) {
          i.totalPage = taskCostTime(i.eaReleaseTime, i.eaUseTime);
        }
        this.dataloading = false
      }).catch(err => {
        this.dataloading = false
        this.isError(err,this)
      })
    },
    //打开添加审批对话框
    addApproval() {
      this.approvalFrom.addApprovalTitle = "添加审批";
      this.getSetApprData();
      this.approvalFrom.number = approvalNumber();
      this.approvalFrom.random = parseInt(
        (Math.random() * 9 + 1) * 100000000000
      );
      this.photoRandom = parseInt((Math.random() * 9 + 1) * 100000000000);
      this.addApprovalDialog = true;
    },
    //关闭审批对话框
    closeApprovalDialog() {
      this.resetForm(this.approvalFromRef, this);
      this.approvalFrom = {
        addApprovalTitle: "",
        affiliation: "",
        eaHouseId: "",
        eaCocId: "",
        eaHomeType: "",
        approver: "",
        approver2: "",
        approver2Opt: [],
        type: "",
        typeOptions: [],
        number: "",
        describe: "",
        content: "",
        money: 0,
        acName: "",
        depBank: "",
        bankAc: "",
        department: "",
        acDesc: "",
        deal1: "",
        deal2: "",
        imgUploadList: [],
        demoOpt: [],

        associationTypes: "其他列表",
        clientScName: "",

        otherListData: [],
        clientListData: [],
        affTotal: 0,
        affPageSize: 5,
        affCur: 1
      };
      this.approvalFrom.addApprovalTitle = "";
      this.addApprovalDialog = false;
    },
    //打开审批归属对话框
    selectAddressBelong(str){//选择地址
      this.affiliationDialogTitle = str
      this.affiliationDialog = true;
    },
    selectRoomDblclick(row){
      if (row) {
        console.log(row);
        console.log(this.approvalFrom);
        if (row.checkList == "房间列表") {
          this.approvalFrom.affiliation = row.cocContacts
          this.approvalFrom.eaCocId = row.cocId
        }else{
          this.approvalFrom.affiliation = row.address
          this.approvalFrom.hsId = row.hsId;
        }
      }
      this.affiliationDialog = false;
    },

    //打开选择账号对话框
    selAcc() {
      this.getBankAccData();
      this.selBankAccDia = true;
    },
    //双击选择账号
    dbSelAcc(row) {
      this.approvalFrom.acName = row.csAccountName;
      this.approvalFrom.depBank = row.csBank;
      this.approvalFrom.bankAc = row.csAccountUmber;
      this.approvalFrom.department = row.csSubordinateBranch;
      this.approvalFrom.acDesc = row.csAccountDescription;
      this.selBankAccDia = false;
    },
    //账号筛选
    scBankChange() {
      this.getBankAccData();
    },

    approvalTypeChange(val) {
      console.log(val);
      console.log(this.approvalFrom.typeOptions);
      this.resetForm(this.approvalFromRef, this);

      if (val == "" || val == "自由流程") {
        this.isPost = false;
        this.isArea = false;
        this.approvalFrom.content = "";
        this.approvalFrom.approver = [];
      } else {
        let arr = [];
        for (let i = 0; i < this.approvalFrom.typeOptions.length; i++) {
          if (val == this.approvalFrom.typeOptions[i].jeapId) {
            if (this.approvalFrom.typeOptions[i].jeapMethod == "按岗位") {
              this.isPost = true;
              console.log("按岗位");
              this.approvalFrom.approver2 = ""
              this.sectionChange(this.approvalFrom.typeOptions[i].jeapId)
              this.isArea = false;
            } else {
              this.isPost = false;
              if (
                Object.keys(this.approvalFrom.typeOptions[i].jeapProcess[0])
                  .length == 2
              ) {
                let array = [];
                for (let j in this.userNameList) {
                  if (
                    this.approvalFrom.typeOptions[i].jeapProcess[0].storeId ==
                    this.userNameList[j].value
                  ) {
                    array.push(this.userNameList[j]);
                  }
                }
                this.approvalFrom.demoOpt = array;
                this.isArea = true;
              } else if (
                Object.keys(this.approvalFrom.typeOptions[i].jeapProcess[0])
                  .length == 3
              ) {
                let array = [
                  {
                    label: "",
                    value: "",
                    children: []
                  }
                ];
                for (let j in this.userNameList) {
                  if (
                    this.approvalFrom.typeOptions[i].jeapProcess[0].storeId ==
                    this.userNameList[j].value
                  ) {
                    array[0].label = this.userNameList[j].label;
                    array[0].value = this.userNameList[j].value;
                    for (let k in this.userNameList[j].children) {
                      if (
                        this.approvalFrom.typeOptions[i].jeapProcess[0]
                          .departmentId ==
                        this.userNameList[j].children[k].value
                      ) {
                        array[0].children.push(
                          this.userNameList[j].children[k]
                        );
                      }
                    }
                  }
                }
                this.approvalFrom.demoOpt = array;
                this.isArea = true;
              } else if (
                Object.keys(this.approvalFrom.typeOptions[i].jeapProcess[0])
                  .length == 4
              ) {
                arr.push(
                  this.approvalFrom.typeOptions[i].jeapProcess[0].storeId
                );
                arr.push(
                  this.approvalFrom.typeOptions[i].jeapProcess[0].departmentId
                );
                arr.push(
                  this.approvalFrom.typeOptions[i].jeapProcess[0].handlerId
                );
                this.approvalFrom.content = this.approvalFrom.typeOptions[
                  i
                ].jeapContent;
                this.isArea = false;
              }
            }
          }
        }
        this.approvalFrom.approver = arr;
      }
      console.log(this.approvalFrom.approver);
    },
    //添加审批
    clickAddApproval() {
      if (this.submitForm(this.approvalFromRef, this)) {
        let isAcc =
          this.approvalFrom.acName == "" ||
          this.approvalFrom.depBank == "" ||
          this.approvalFrom.bankAc == "";
        if (isAcc && this.isMore) {
          this.$message.warning("收款账号为空，请输入收款账号！");
          return
        }
        this.$axios({
          url: "/common1/approvalPapers",
          method: "post",
          data: {
            eaCocId: this.approvalFrom.eaCocId,
            eaStoreId: this.approvalFrom.hsId,
            eaEventHandler: this.approvalFrom.approver[2] || this.approvalFrom.approver2,
            eaProcessId: this.approvalFrom.type == "自由流程" ? "" : this.approvalFrom.type,
            eaApprovalNumber: this.approvalFrom.number,

            eaEventContent: this.approvalFrom.content,
            eaHomeType: this.approvalFrom.eaHomeType,

            sendMessageFlag: this.approvalFrom.deal1 == true ? "1" : "2",
            priorityFlag: this.approvalFrom.deal2 == true ? "1" : "2",
            addCommunity: this.approvalFrom.affiliation,

            eaAmountInvolved: this.approvalFrom.money,
            eaBankUsername: this.approvalFrom.acName,
            eaBankName: this.approvalFrom.depBank,
            eaBankAccountNumber: this.approvalFrom.bankAc,
            eaSubordinateBranch: this.approvalFrom.department,
            eaBankAccountDescription: this.approvalFrom.acDesc,

            tableType: "审批",
            randomNum: this.photoRandom,
          }
        }).then(res => {
          this.$message.success("添加成功");
          this.getApprovalData();
          this.closeApprovalDialog();
          this.addApprovalDialog = false;
        }).catch(err => {
          this.isError(err,this)
        });
      }
    },
    //修改审批
    clickModiApproval() {
      if (this.submitForm(this.approvalFromRef, this)) {
        let isAcc =
          this.approvalFrom.acName == "" ||
          this.approvalFrom.depBank == "" ||
          this.approvalFrom.bankAc == "";
        if (isAcc && this.isMore) {
          this.$message.warning("收款账号为空，请输入收款账号！");
          return false;
        }
        this.$axios({
          url: `/common1/approvalPapers/${this.approvalFrom.eaId}`,
          method: "put",
          data: {
            eaCocId: this.approvalFrom.eaCocId,
            eaHouseId: this.approvalFrom.eaHouseId,
            eaEventHandler: this.approvalFrom.approver[2] || this.approvalFrom.approver2,
            eaProcessId:this.approvalFrom.type == "自由流程"? "": this.approvalFrom.type,

            eaApprovalNumber: this.approvalFrom.number,
            eaEventContent: this.approvalFrom.content,
            eaHomeType: this.approvalFrom.eaHomeType,
            sendMessageFlag: this.approvalFrom.deal1 == true ? "1" : "2",
            priorityFlag: this.approvalFrom.deal2 == true ? "1" : "2",
            addCommunity: this.approvalFrom.affiliation,
            eaAmountInvolved: this.approvalFrom.money,
            eaBankUsername: this.approvalFrom.acName,
            eaBankName: this.approvalFrom.depBank,
            eaBankAccountNumber: this.approvalFrom.bankAc,
            eaSubordinateBranch: this.approvalFrom.department,
            eaBankAccountDescription: this.approvalFrom.acDesc,
            tableType: "审批",
            randomNum: this.photoRandom,
          }
        }).then(res => {
          this.$message.success("修改成功");
          this.getApprovalData();
          this.closeApprovalDialog();
          this.addApprovalDialog = false;
        }).catch(err => {
          this.isError(err,this)
        });
      }
    },
    // 表格一行选中
    rowClick(row) {
      this.clickrow = row;
      this.noticeRow=row
    },
    //把每一行的索引放进row
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex;
    },
    //点击修改审批
    clickModifyApproval() {
      console.log(this.clickrow);
      if (!this.clickrow) {
        this.$message.warning("请先选中一条记录");
        return;
      }
      if(this.clickrow.eaEventState != "处理中"){
        this.$message.warning("该审批不可修改");
        return;
      }
      if(this.clickrow.eaProcessId != undefined){
        this.$message.warning("该审批走指定流程，不支持修改");
        return;
      }
      let userCoding = sessionStorage.getItem('userCoding')
      console.log(userCoding)
      console.log(this.clickrow.eaEventPublisher)
      console.log(this.clickrow.eaEventHandler)
      if(this.clickrow.eaEventPublisher != userCoding || this.clickrow.eaEventHandler != userCoding){
        this.$message.warning("只有申请人可以修改审批，并且申请人在处理状态");
        return;
      }
      this.photoRandom = parseInt((Math.random() * 9 + 1) * 100000000000)
      this.getSetApprData();
      this.approvalFrom.eaId = this.clickrow.eaId;
      if (this.clickrow.publisherName != undefined) {
        this.approvalFrom.publisherName = this.clickrow.publisherName;
      }
      this.approvalFrom.affiliation = this.clickrow.detailAddress;

      for (let i = 0; i < this.userName.length; i++) {
        if (this.clickrow.eaEventHandler == this.userName[i].userCoding) {
          this.approvalFrom.approver = [
            this.userName[i].storefrontId,
            this.userName[i].departmentId,
            this.userName[i].userCoding
          ];
          break;
        }
      }

      this.approvalFrom.type = this.clickrow.eaProcessId;
      this.approvalFrom.eaEventType = this.clickrow.eaEventType;

      if (this.approvalFrom.type == undefined) {
        this.approvalFrom.type = "";
      }
      this.approvalFrom.number = this.clickrow.eaApprovalNumber;
      let imgArr = [];
      if (this.clickrow.eaImgPath != undefined) {
        let imgList = JSON.parse(this.clickrow.eaImgPath);
        for (let i = 0; i < imgList.length; i++) {
          let obj = {
            name: imgList[i].name,
            url: imgList[i].path
          };
          imgArr.push(obj);
        }
        this.approvalFrom.imgUploadList = imgArr;
      }

      this.approvalFrom.content = this.clickrow.eaEventContent;

      this.approvalFrom.addApprovalTitle = "修改审批";

      if (this.clickrow.eaHomeType == "其他审批") {
        this.approvalFrom.eaHouseId = this.clickrow.eaHouseId;
        this.approvalFrom.eaCocId = "";
        this.approvalFrom.eaHomeType = "其他审批";
      } else if (this.clickrow.eaHomeType == "房间审批") {
        this.approvalFrom.eaHouseId = "";
        this.approvalFrom.eaCocId = this.clickrow.eaCocId;
        this.approvalFrom.eaHomeType = "房间审批";
      }
      this.approvalFrom.money = this.clickrow.eaAmountInvolved;
      this.approvalFrom.acName = this.clickrow.eaBankUsername;
      this.approvalFrom.depBank = this.clickrow.eaBankName;
      this.approvalFrom.bankAc = this.clickrow.eaBankAccountNumber;
      this.approvalFrom.department = this.clickrow.eaSubordinateBranch;
      this.approvalFrom.acDesc = this.clickrow.eaBankAccountDescription;
      this.addApprovalDialog = true;
    },
    //设置审批流程
    setApproval() {
      this.setApprovalData = [];
      this.setApprovalDialog = true;
      this.getSetApprData();
    },
    flowStateChange() {
      this.setApprovalData = [];
      this.getSetApprData();
    },
    getSetApprData() {
      this.$axios({
        url: "/common1/examineApprovalProcess",
        method: "get",
        params: {
          jeapState: this.approvalFlowFrom.selectStatus
        }
      }).then(res => {
        this.setApprovalData = [];
        this.approvalFrom.typeOptions = res.data.result;
        for (let i = 0; i < res.data.result.length; i++) {
          this.setApprovalData.push(res.data.result[i]);
          this.setApprovalData[i].approvalFlow = "";
          this.setApprovalData[i].jeapProcess = JSON.parse(
            res.data.result[i].jeapProcess
          );
          for (let j = 0; j < this.setApprovalData[i].jeapProcess.length; j++) {
            this.setApprovalData[i].approvalFlow += `${j + 1}.${
              this.setApprovalData[i].jeapProcess[j].handler
            }>>`;
          }
          this.setApprovalData[i].approvalFlow = this.setApprovalData[
            i
          ].approvalFlow.substring(
            0,
            this.setApprovalData[i].approvalFlow.length - 2
          );
        }
        console.log(this.approvalFrom.typeOptions)
      }).catch(err => {
        this.isError(err,this)
      })
    },
    // 关闭审批流程对话框
    closeSetApprovalDialog() {
      this.approvalFlowFrom.status = 1;
      this.setApprovalData = [];
    },
    //添加流程对话框
    addFlow() {
      this.addApprovalFlowDialog = true;
      this.approvalFlowFrom.title = "添加审批流程";
    },
    //关闭添加流程对话框
    closeApprovalFlowDialog() {
      this.resetForm(this.approvalFlowformRef, this);
      this.approvalFlowFrom = {
        selectStatus: "1",
        type: "",
        number: "",
        flowType: "按部门",
        template: "",
        section: "业务人员",
        steps: []
      };
    },
    //选择阶梯数
    selectSteps(value) {
      //6
      this.approvalFlowFrom.steps = [];
      for (let i = 0; i < value; i++) {
        let obj = {
          number: i + 1,
          approver: ""
        };
        this.approvalFlowFrom.steps.push(obj);
      }
    },
    //添加审批流程
    clickAddFlow() {
      console.log(this.submitForm(this.approvalFlowformRef, this));
      if (this.submitForm(this.approvalFlowformRef, this)) {
        let arr = [];
        let data = this.approvalFlowFrom.steps;
        let handler = "";
        if (this.approvalFlowFrom.flowType == "按岗位") {
          handler = [
            {
              storeId: "",
              departmentId: "",
              handlerId: "",
              handler: this.approvalFlowFrom.section
            }
          ];
        }
        for (let i = 0; i < data.length; i++) {
          let approver = data[i].approver;
          let obj = {
            storeId: approver[0],
            departmentId: approver[1],
            handlerId: approver[2]
          };
          arr.push(obj);
        }
        console.log(arr);

        this.$axios({
          url: "/common1/examineApprovalProcess",
          method: "post",
          data: {
            jeapType: this.approvalFlowFrom.type,
            jeapMethod: this.approvalFlowFrom.flowType,
            jeapProcess:
              this.approvalFlowFrom.flowType != "按岗位"
                ? JSON.stringify(arr)
                : JSON.stringify(handler),
            jeapContent: this.approvalFlowFrom.template
          }
        }).then(res => {
          this.$message.success("添加成功");
          this.getSetApprData();
          this.closeSetApprovalDialog();
          this.addApprovalFlowDialog = false;
        }).catch(err => {
          this.isError(err,this)
        });
      }
    },
    //打开修改流程对话框
    modifyFlow() {
      if (!this.clickSetRow) {
        this.$message.warning("请先选中一条记录");
      } else {
        this.approvalFlowFrom.title = "修改审批流程";
        this.selectSteps(this.clickSetRow.jeapProcess.length);
        this.addApprovalFlowDialog = true;
        //赋值
        this.approvalFlowFrom.type = this.clickSetRow.jeapType;
        this.approvalFlowFrom.number = this.clickSetRow.jeapProcess.length;
        this.approvalFlowFrom.flowType = this.clickSetRow.jeapMethod;
        this.approvalFlowFrom.template = this.clickSetRow.jeapContent;
        //审核人赋值处理
        let arr = [];
        let data = this.clickSetRow.jeapProcess;
        for (let i = 0; i < data.length; i++) {
          let obj = {
            approver: [
              data[i].storeId,
              data[i].departmentId,
              data[i].handlerId
            ],
            number: i + 1
          };
          arr.push(obj);
        }
        this.approvalFlowFrom.steps = arr;
      }
    },
    //修改流程保存
    clickModiFlow() {
      if (this.submitForm(this.approvalFlowformRef, this)) {
        let arr = [];
        let data = this.approvalFlowFrom.steps;
        for (let i = 0; i < data.length; i++) {
          let approver = data[i].approver;
          let obj = {
            storeId: approver[0],
            departmentId: approver[1],
            handlerId: approver[2]
          };
          arr.push(obj);
        }
        console.log(arr);

        this.$axios({
          url: `/common1/examineApprovalProcess/${this.clickSetRow.jeapId}`,
          method: "put",
          data: {
            jeapType: this.approvalFlowFrom.type,
            jeapMethod: this.approvalFlowFrom.flowType,
            jeapProcess: JSON.stringify(arr),
            jeapContent: this.approvalFlowFrom.template
          }
        })
          .then(res => {
            console.log(res);
            this.$message.success("修改成功");
            this.getSetApprData();
            this.clickSetRow = "";
            this.closeSetApprovalDialog();
            this.addApprovalFlowDialog = false;
          })
          .catch(err => {
            this.isError(err,this)
          });
      }
    },
    //注销流程
    logoutFlow() {
      if (!this.clickSetRow) {
        this.$message.warning("请先选中一条记录");
      } else {
        this.$confirm("请确认是否注销？").then(() => {
          this.$axios({
            url: `/common1/examineApprovalProcess/close/${this.clickSetRow.jeapId}`,
            method: "put",
            data: {
              jeapState: 0
            }
          }).then(res => {
            this.$message.success("注销成功");
            this.clickSetRow = "";
            this.getSetApprData();
          }).catch(err => {
            this.isError(err,this)
          })
        });
      }
    },
    //启用流程
    startFlow() {
      if (!this.clickSetRow) {
        this.$message.warning("请先选中一条记录");
      } else {
        this.$confirm("请确认是否启用？").then(() => {
          this.$axios({
            url: `/common1/examineApprovalProcess/open/${this.clickSetRow.jeapId}`,
            method: "put",
            data: {
              jeapState: 1
            }
          })
            .then(res => {
              this.getSetApprData();
              this.$message.success("启用成功");
            })
            .catch(err => {
              this.isError(err,this)
            });
        });
      }
    },
    scBlur() {
      this.getApprovalData();
    },
    //添加设置审批流程选中行
    rowSetApprova(row) {
      this.clickSetRow = row;
      console.log(this.clickSetRow);
    },
    //双击一行数据
    dbRow(row) {
      this.noticeRow=row
      this.$axios({
        url: `/common1/approvalPapers/${row.eaId}`,
        method: "get"
      }).then(res => {
        this.detailData = res.data.result;
        console.log(this.detailData);

        if (typeof this.detailData.eaTreatmentOpinion == "string") {
          this.detailData.eaTreatmentOpinion = JSON.parse(
            `[${this.detailData.eaTreatmentOpinion}]`
          );
          this.approvalBill = this.detailData.eaTreatmentOpinion;
          if (this.detailData.eaTreatmentOpinion.length > 5) {
            this.detailData.count = 0;
          } else {
            this.detailData.count =
              5 - this.detailData.eaTreatmentOpinion.length;
          }
        } else {
          if (this.detailData.eaTreatmentOpinion == undefined) {
            this.detailData.count = 5;
          }
          this.approvalBill = this.detailData.eaTreatmentOpinion;
        }
        this.approvalDetailDialog = true;
      }).catch(err => {
        this.isError(err,this)
      })
    },

    next(state) {
      let data = this.getIndex(this.approvalData, this.clickrow, state, this);
      if(!data) {
        return
      }
      this.clickrow = data;
      this.$refs.approvalTable.setCurrentRow(data);
      this.dbRow(data);
    },

    //点击打开审批处理
    clickApproval() {
      this.dealform.state = "处理中";
      this.dealform.money = this.clickrow.eaAmountInvolved;
      let jsonArray = [];
      this.photoRandom = parseInt((Math.random() * 9 + 1) * 100000000000);
      this.getPhoto();

      if (this.clickrow.eaEventType != "自由流程") {
        jsonArray = JSON.parse(this.clickrow.eaProcess);
        jsonArray.splice(0, 1);
        console.log(jsonArray);
        this.dealform.nextApr = jsonArray;
      }
      if (jsonArray.length == 0) {
        this.dealform.state = "已完成";
      } else {
        let data = jsonArray[0];
        let approver = [data.storeId, data.departmentId, data.handlerId];
        this.dealform.handlerId = data.handlerId;
        this.dealform.approver = approver;
      }
      this.dealDialog = true;
    },
    closeDealDialog() {
      this.recoverImg();
      this.dealform = {
        state: "处理中",
        approver: "",
        handlerId: "",
        money: "",
        topic: "",
        opinion: "",
        topic2: "",
        nextApr: [],
        imgUploadList: [],
        imgDelId: []
      };
    },
    dealApproval() {
      console.log(this.dealform);
      if (
        this.detailData.eaEventType == "自由流程" &&
        this.dealform.state == "处理中"
      ) {
        this.dealform.handlerId = this.dealform.approver[2];
      }
      console.log(this.dealform.nextApr);

      this.$axios({
        url: `/common1/approvalPapers/handle/${this.detailData.eaId}`,
        method: "put",
        data: {
          eaEventState: this.dealform.state,
          eaEventHandler:
            this.dealform.state == "处理中" ? this.dealform.handlerId : "",
          eaTreatmentOpinion: this.dealform.opinion,
          eaProcess:
            this.dealform.state == "处理中"
              ? JSON.stringify(this.dealform.nextApr)
              : null,
          addFinancialFlag: 0,
          sendMessageFlag: this.dealform.topic == true ? 1 : 2,
          eaAmountInvolved: this.dealform.money,
          deleteImgList: this.dealform.imgDelId,
          randomNum: this.photoRandom,
          tableType: "审批"
        }
      })
      .then(res => {
        this.$message.success("审批成功");
        this.getApprovalData();
        this.closeDealDialog();
        this.dealDialog = false;
        this.approvalDetailDialog = false;
      })
      .catch(err => {
        this.isError(err,this)
      })
    },
    //打开收支对话框
    clickIncExp() {
      console.log(this.detailData);
      this.getVar();
      this.photoRandom = parseInt((Math.random() * 9 + 1) * 100000000000);
      this.incExpFrom.affType = "其他类";
      this.incExpFrom.moneyAff = `联系人：${this.detailData.eaBankUsername}，电话：`;
      this.incExpFrom.affName = this.detailData.detailAddress;
      this.incExpFrom.accDate = moment().format('YYYY-MM-DD')
      this.incExpFrom.money = this.detailData.eaAmountInvolved;
      this.incExpFrom.number = this.detailData.eaApprovalNumber;
      this.incExpDia = true;
    },
    //选择分类
    selClas(val) {
      console.log(val);
      this.incExpFrom.nature = val[0];
      this.incExpFrom.incExpClas = val[1];
      this.incExpFrom.incExpKind = val[2];
    },
    //选择账号
    selAccType(val) {
      console.log(val);
      this.$axios({
        url: `/common1/bankAccounts/type`,
        method: "get",
        params: {
          faPaymentType: val
        }
      }).then(res => {
        console.log(res);
        this.incExpFrom.accNameOpt = res.data.result;
      }).catch(err => {
        this.isError(err,this)
      })
    },
    //根据账号自动填写
    selAccName(val) {
      console.log(val);
      for (const item of this.incExpFrom.accNameOpt) {
        console.log(item);
        if (val == item.faId) {
          this.incExpFrom.account = item.faAccount;
          this.incExpFrom.accAff = item.faBelonging;
        }
      }
    },

    flowTypeChange(val) {
      if (val == "按岗位") {
        this.approvalFlowFrom.number = "1";
        this.$axios({
          url: `/common1/companyVar/1`,
          method: "get"
        }).then(res => {
          this.approvalFlowFrom.sectionOpt = JSON.parse(
            res.data.result.userType
          );
          console.log(this.approvalFlowFrom.sectionOpt);
        }).catch(err => {
          this.isError(err,this)
        })
      } else {
        this.selectSteps("1");
      }
    },
    sectionChange(id) {
      this.$axios({
        url: `/common1/examineApprovalProcess/checkStation/${id}`,
        method: "get"
      }).then(res => {
        console.log(res);
        if (res.data.result.length == 0) this.$message.warning("您所属部门不存在该岗位的人员")
        this.approvalFrom.approver2Opt = res.data.result
      }).catch(err => {
        this.isError(err,this)
        this.approvalFrom.approver2 = ""
      })
    },
    //选择数据条数
    sizeChange(pageSize) {
      this.pageSize = pageSize;
      this.getApprovalData();
    },
    //具体那一页
    currentChange(current) {
      this.currentPage = current;
      this.noticeRow = "";
      this.getApprovalData();
    },
    //选择数据条数
    sizeChange2(pageSize) {
      this.pageSize2 = pageSize;
      this.getBankAccData();
    },
    //具体那一页
    currentChange2(current) {
      this.currentPage2 = current;
      this.getBankAccData();
    },
    //归属分页
    affSizeChange(pageSize) {
      this.approvalFrom.affPageSize = pageSize;
      if (this.approvalFrom.associationTypes == "其他列表") {
        this.getOtherListData();
      } else {
        this.getClientListData();
      }
    },
    affCurrentChange(curr) {
      this.approvalFrom.affCur = curr;
      if (this.approvalFrom.associationTypes == "其他列表") {
        this.getOtherListData();
      } else {
        this.getClientListData();
      }
    },
    //获取银行账号
    getVar() {
      this.$axios({
        url: `/common1/companyVar/1`,
        method: "get"
      }).then(res => {
        console.log(res);
        this.incExpFrom.accTypeOpt = JSON.parse(res.data.result.acountType);
        console.log(this.incExpFrom.accTypeOpt);
      }).catch(err => {
        this.isError(err,this)
      })
    },
    //生成账单
    generateBills(type) {
      if(this.submitForm(['incExpFrom'],this)){
        let jfFinanNote =
          this.detailData.eaEventType +
          "审批生成收支,申请内容：" +
          this.detailData.eaEventContent +
          ";付款账户：开户银行-账号名称-" +
          this.detailData.eaBankAccountNumber +
          ";" +
          this.incExpFrom.season;

        let obj = [
          {
            // "jfRenterId": "",
            // "jfLandlordId": "",
            // "jfHouse4rentId": "",
            // "jfHouse4storeId": "",
            storefront: this.incExpFrom.handler[0],
            department: this.incExpFrom.handler[1],
            jfHandlers: this.incExpFrom.handler[2],
            jfHouseId: this.detailData.eaHouseId,
            jfAccountingWhy: this.incExpFrom.moneyAff, //费用归属
            // jfAccountId: this.incExpFrom.accName, //账户名称
            faId: this.incExpFrom.accName, //账户名称
            jfFinancialCoding:
              moment().format('YYYYMMDDHHmmss') +
              Math.floor(Math.random() * 10) +
              Math.floor(Math.random() * 10) +
              Math.floor(Math.random() * 10),
            jfClosedWay: this.incExpFrom.accType, //现金A类
            jfPayType: this.incExpFrom.accMethod, //现钞
            jfNatureOfThe: this.incExpFrom.nature, //收入
            jfBigType: this.incExpFrom.incExpClas, //主营类
            jfAccountingSpecies: this.incExpFrom.incExpKind, //收支种类
            jfTheOwnershipType: this.incExpFrom.affType, //归属类型
            jfBelongingToTheName: this.incExpFrom.affName, //归属名称
            jfTicketNumber: this.incExpFrom.number, //票据编号
            jfSumMoney: this.incExpFrom.money, //收支金额
            jfOperationRecords:
              moment().format('YYYY-MM-DD HH:mm:ss') + "，添加收支记录。",
            jfBillingDate: this.incExpFrom.accDate, //记账日期
            jfStartCycle:
              this.incExpFrom.period.length == 0 ? [] : this.incExpFrom.period[0],
            jfEndCycle:
              this.incExpFrom.period.length == 0 ? [] : this.incExpFrom.period[1],
            jfFinanNote: jfFinanNote
          }
        ];
        console.log(obj);

        this.$axios({
          url: `/common1/approvalPapers/payment/${this.detailData.eaId}`,
          method: "post",
          data: {
            eaFinancialCoding:
              moment().format('YYYYMMDDHHmmss') +
              Math.floor(Math.random() * 10) +
              Math.floor(Math.random() * 10) +
              Math.floor(Math.random() * 10),
            eaWhetherGenerateRecord: "是",
            addFinancialFlag: type,
            jsonArray: JSON.stringify(obj)
          }
        }).then(res => {
          this.$message.success("生成收支成功");
          this.incExpDia = false;
          this.approvalDetailDialog = false;
          this.getApprovalData();
        }).catch(err => {
          this.isError(err,this)
        })
      }
    },
    incExpDiaClose(){//收支清空
      this.resetForm(['incExpFrom'],this)
      this.incExpFrom = {
        affType: "",//归属类型
        affName: "",//归属名称
        accDate: "",//记账日期
        moneyAff: "",//费用归属
        handler: "",//经手人

        accType: "",//账号类型
        accTypeOpt: [],//账号类型数组
        accName: "",//账号名称
        accNameOpt: [],//账号名称数组
        accMethod: "",//收支方式

        account: "",//账户号码
        accAff: "",//账号归属
        nature: "",//收支性质
        incExpClas: "",//收支分类
        incExpKind: "",//收支种类
        
        selSort: "",//选择分类
        money: "",//收支金额
        number: "",//票据编号
        period: null,//归属周期
        season: "",//收支原因
      }
    },
    //手机上传
    phoneUpload() {
      if (this.approvalFrom.addApprovalTitle == "添加审批") {
        this.getPhoneUploadUrl(this.approvalFrom.random, "私有", "图片", null);
      } else {
        this.getPhoneUploadUrl(
          this.dealform.random,
          "私有",
          "图片",
          this.detailData.eaId
        );
      }
      this.phoneUploadDia = true;
    },
    //关闭手机上传
    closePhoneUploadDia() {
      if (this.approvalFrom.addApprovalTitle == "添加审批") {
        this.getTempImg(this.approvalFrom.random);
      } else {
        this.getTempImg(this.dealform.random);
      }
    },
    //恢复图片
    recoverImg() {
      this.$axios({
        url: `/common1/photos/recovery/${this.detailData.eaId}`,
        method: "PUT",
        params: {
          photoTableType: "审批"
        }
      }).catch(err => {
        this.isError(err,this)
      })
    },
    //获取手机上传url
    getPhoneUploadUrl(random, bucket, fileType, tableId) {
      this.$axios({
        url: `/common1/photos/getMobUploadUrl`,
        method: "get",
        params: {
          photoRandom: random,
          photoBucket: bucket,
          photoMimeType: fileType,
          photoTableType: "审批",
          photoTableId: tableId,
          photoUrl: null,
          photoUserId: null
        }
      }).then(res => {
        this.QRCodeMsg = res.data.result;
      }).catch(err => {
        this.isError(err,this)
      })
    },
    //查询临时图片
    async getTempImg(random) {
      try {
        let imgArr = [];
        let imgdata = [];
        await this.$axios({
          url: `/common1/photos`,
          method: "get",
          params: {
            photoRandom: random,
            photoTableType: "审批",
            photoStatus: "临时"
          }
        }).then(res => {
          imgdata = res.data.result;
        }).catch(err => {
          this.isError(err,this)
        })
        for (let i = 0; i < imgdata.length; i++) {
          let obj = {
            name: imgdata[i].photoName,
            url: await this.getDownloadToken(imgdata[i].photoUrl),
            response: {
              result: imgdata[i].photoId
            }
          };
          imgArr.push(obj);
        }
        if (this.approvalFrom.addApprovalTitle == "添加审批") {
          if (this.approvalFrom.imgUploadList.length == 0) {
            this.approvalFrom.imgUploadList = imgArr;
          } else {
            for (const item1 of imgArr) {
              let flag = true;
              for (const item2 of this.approvalFrom.imgUploadList) {
                if (item1.response.result == item2.response.result) {
                  flag = false;
                  break;
                }
              }
              if (flag) {
                this.approvalFrom.imgUploadList.push(item1);
              }
            }
          }
        } else {
          if (this.dealform.imgUploadList.length == 0) {
            this.dealform.imgUploadList = imgArr;
          } else {
            for (const item1 of imgArr) {
              let flag = true;
              for (const item2 of this.dealform.imgUploadList) {
                if (item1.response.result == item2.response.result) {
                  flag = false;
                  break;
                }
              }
              if (flag) {
                this.dealform.imgUploadList.push(item1);
              }
            }
          }
        }
      } catch (err) {
        console.log(err);
      }
    },

    //查询已保存的图片
    async getPhoto() {  
      try {
        let imgArr = [];
        let imgdata = [];
        await this.$axios({
          url: `/common1/photos`,
          method: "get",
          params: {
            photoTableId: this.noticeRow.eaId,
            photoTableType: "审批",
            photoStatus: "正常"
          }
        }).then(res => {
          imgdata = res.data.result;
        }).catch(err => {
          this.isError(err,this)
        })
        for (let i = 0; i < imgdata.length; i++) {
          let obj = {
            name: imgdata[i].photoName,
            url: await this.getDownloadToken(imgdata[i].photoUrl),
            response: {
              result: imgdata[i].photoId
            }
          };
          imgArr.push(obj);
        }
        this.dealform.imgUploadList = imgArr;
      } catch (err) {
        console.log(err);
      }
    },
    //获取下载凭证
    getDownloadToken(str) {
      return this.$axios({
        url: `/common1/photos/getDownloadUrl`,
        method: "get",
        params: {
          baseUrls: str
        }
      }).then(res => {
        return res.data.result[0].original;
      }).catch(err => {
        this.isError(err,this)
      })
    },
    //获取token
    getToken() {
      return this.$axios({
        url: "/common1/photos/token/callback",
        method: "get",
        params: {
          bucket: "私有"
        }
      }).then(res => {
        return res.data.result;
      }).catch(err => {
        this.isError(err,this)
      })
    },

    dialogImgPathClose(){//上传图片弹窗关闭 
      this.dialogImgPath=false
    },
    uploadInAddApprovalDialog(){//添加修改审批上传图片
      this.needSavePhoto=false//需要保存按钮
      this.photoTableId=this.approvalFrom.addApprovalTitle =="修改审批"?this.clickrow.eaId:''
      this.photoTableType='审批'
      this.dialogImgPath=true
      this.$nextTick(()=>{
        this.$refs.upload.photoReflash()
      })
    },
    uploadInIncExpDia(){//双击弹框付款按钮上传图片
      this.needSavePhoto=false//需要保存按钮
      this.photoTableId=this.detailData.eaId
      this.photoTableType='审批'
      this.dialogImgPath=true
      this.$nextTick(()=>{
        this.$refs.upload.photoReflash()
      })
    },
    uploadInDealDialog(){//审批上传附件
      this.needSavePhoto=false//需要保存按钮
      this.photoTableId=this.detailData.eaId
      this.photoTableType='审批'
      this.dialogImgPath=true
      this.$nextTick(()=>{
        this.$refs.upload.photoReflash()
      })
    },

    //打印相关
    clickPrint() {
      this.printDialog = true;
    },
  },
};
</script>


<style lang="scss" scoped>
table td {
  border: 1px solid #888;
  border-top: none;
  white-space: wrap;
  padding: 2px;
  font-size: 13px;
}

table {
  table-layout: fixed;
  width: 770px;
  font-size: 12px;
  margin: 0 10px;
}

#body {
  border: 1px solid black;
  width: 790px;
  height: 450px;
}

#title {
  font-size: 18px;
  text-align: center;
  font-weight: bold;
}

#header {
  font-size: 16px;
  text-align: center;
  margin: 10px;
  font-weight: bold;
}

#footer {
  margin: 20px 20px;
}

#footer div {
  float: left;
  width: 20%;
  font-weight: bold;
}
.label {
  font-size: 12px;
  color: #333;
}
.describe /deep/.el-col {
  font-size: 14px;
}
</style>
